<!-- 草稿列表项 -->
<template>
    <div class="draft-item-ctn">
        <div class="draft-item-title">
            {{  drafrData.title  }}
        </div>
        <div class="draft-item-desription">
            <div>
                {{  drafrData.summary  }}
            </div>
        </div>
        <div class="draft-item-footer">
            <div class="last-modify-date">
                编辑于{{  drafrData.updatedTime  }}
            </div>
            <div style="flex:1;">

            </div>
            <div class="draft-operations">
                <div class="draft-operation" @click="handleGoToEdit">
                    <svg-icon name="intro-edit" :class="['operation-icon']"></svg-icon>
                    <span class="operation-text"> 编辑 </span>
                </div>

                <div class="draft-operation" @click="handleDelete">
                    <svg-icon name="delete-bin-5-fill" :class="['operation-icon']"></svg-icon>
                    <span class="operation-text"> 删除 </span>
                </div>
            </div>
        </div>
</div>
</template>

<script>
import { message, Modal } from 'ant-design-vue';
import { h } from 'vue-demi';
import { deletePaper } from '@/api/paperManage.js';

export default {
    props: {
        index: {
            default: -1
        },
        drafrData: {
            default: () => ({})
        }
    },
    setup(props, context) {
        const handleDelete = () => {
            Modal.confirm({
                title: '提示',
                content: h('span', { }, [
                    '是否确认删除草稿',
                    h('span', { style: {  color: '#1890ff' } }, props.drafrData.title),
                    '? 删除后无法恢复'
                ] ),
                onOk: async () => {
                    const formData = new FormData();
                    formData.append('paperid',props.drafrData.id )
                    const res =  await deletePaper(formData)
                    if(res.success) {
                        message.success('成功删除');
                        context.emit('onDeleteDraft', props.index);
                    }
                }
            })
        }
        const handleGoToEdit = () => {
            const { $router } = context.root;
            const href =  $router.resolve({
                name: 'bbsDrafts',
                query: {
                    id: props.drafrData.id
                }
            });
            window.open(href.href, '_blank')
        }
        return {
            handleDelete,
            handleGoToEdit
        }
    }
}
</script>

<style lang="less" scoped>
.draft-item-ctn {
    padding: 20px;
    border-bottom: .5px solid #EBEBEB;
    transition: .3s all ease-in-out;
    &:hover {
        background: #F7F7F7;
    }

    .draft-item-title {
        color: rgba(0, 0, 0, 0.85);
        font-size: 18px;
    }

    .draft-item-desription {
        margin-top: 10px;
        color: rgba(0, 0, 0, 0.65);
    }

    .draft-item-footer {
        display: flex;
        align-items: center;
        margin-top: 14px;

        .last-modify-date {
            color: rgba(0, 0, 0, 0.45);
            font-size: 12px;
            white-space: nowrap;
        }

        .draft-operations {
            display: flex;

            .draft-operation {
                display: flex;
                align-items: center;
                transition: .3s all ease-in-out;
                cursor: pointer;
                color: #4E5969;
                .operation-icon {
                    color: #4E5969;
                    fill: #4E5969;
                }
                .operation-text {
                    margin-left: 4px;
                }

                &:hover {
                    .operation-icon {
                        fill: @srims-primary-color;
                        color: @srims-primary-color;

                        
                    }
                    .operation-text {
                            color: @srims-primary-color;

                        }

                }

            }
            .draft-operation + .draft-operation {
                margin-left: 32px;
            }
        }

    }
}</style>