<template>
    <div class="post-list-ctn">
        <a-spin v-show="mainContentLoading && draftList.length == 0" :spinning="mainContentLoading">
            <div style="padding: 16px;">
                <a-skeleton active  title :paragraph="{ rows: 4 }" />
            </div>
        </a-spin>
        <a-spin :spinning="mainContentLoading && draftList.length !== 0" tip="正在加载中...">
            <div ref="listRef">
                <div v-for="(item, index) in draftList" :key="item.id">
                    <DraftItem :index="index" :drafrData="item" @onDeleteDraft="onDeleteDraft"></DraftItem>
                </div>
            </div>
        </a-spin>
        <div v-if="!mainContentLoading && draftList.length === 0" style="padding: 50px;">
            <a-empty></a-empty>
        </div>
        <div class="pagination-ctn default-pagination">
            <a-pagination @change="handlePageChange" v-model="current" :pageSize="pageSize" :total="total" show-quick-jumper
                hideOnSinglePage style="margin: 25px;"/>
        </div>
</div>
</template>

<script>

import { onMounted, ref } from 'vue-demi';
import DraftItem from '../components/draftItem.vue';
import { getDraftPaperList } from '@/api/paperManage.js'

export default {
    components: {
        DraftItem
    },
    setup(props, context) {
        const current = ref(1); // 当前页
        const total = ref(0); // 总页数
        const pageSize = ref(20); // 单页数目
        const mainContentLoading = ref(true);   // 是否正在加载
        const draftList = ref([]);   // 帖子列表

        // 页数改变
        const handlePageChange = async () => {
            mainContentLoading.value = true
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
            const res = await getDraftPaperList({
                pageno: current.value,
                pagesize: pageSize.value
            });
            if(res.success) {
                draftList.value = res.data.rows ? res.data.rows  : [];
                total.value = res.data.totalRows;
                context.emit('onChangeDraftCount',res.data.totalRows);
            }
           
           /*  setTimeout(() => {
                draftList.value = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
                mainContentLoading.value = false;
                total.value = 1000;
            }, 2000) */
            mainContentLoading.value = false
        }
        // 
        onMounted(() => {
            handlePageChange();
        })

        // 删除草稿成功后的回调
        const onDeleteDraft = (index) => {
            total.value = total.value -1;
            draftList.value.splice(index, 1);
            /* total.value = total.value -1 */
            context.emit('onChangeDraftCount',total.value);
        }

        return {
            mainContentLoading,
            draftList,
            current,
            total,
            pageSize,
            handlePageChange,
            onDeleteDraft
        }
    }
}
</script>

<style lang="less" scoped>
.pagination-ctn {
   // padding: 25px;
    text-align: center;
    //border-left: .5px solid #ebebeb;
    // border-right: .5px solid #ebebeb;
    //border-bottom: .5px solid #ebebeb;
}
</style>